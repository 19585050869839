import { DrawingList } from '../components/Drawing';

function DrawingsIndexRoute() {
  return <DrawingList />;
}

export const Component = DrawingsIndexRoute;

export function clientLoader() {
  return null;
}
